interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {
    return (
        <div className="w-full bg-slate-800">
            <div className="container mx-auto flex items-center gap-8 md:gap-12 px-4 md:px-8">
                <img src="https://www.entrima.org/wp-content/themes/entrima/resources/img/entrima-logo.webp" alt="ENTRIMA" className="h-20" />
                <h1 className="text-white text-2xl small-caps font-medium">
                    {title || 'Video Lessons'}
                </h1>
            </div>
        </div>
    );
}

export default Header;