import { useEffect, useState } from 'react';
import Layout from '../Layouts/Layout';
import Categories from '../Components/Categories';
import Videos from '../Components/Videos';
import { AppVideoCategoryApiResponse, Video, VideoCategory } from '../types';
import Header from '../Components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_REQUEST_ARGS } from '../config';
import api from '../Services/api';
import he from 'he';

const Main = () => {
    const [category, setCategory] = useState<VideoCategory>();
    const [videos, setVideos] = useState<Video[]>([]);

    const navigate = useNavigate();

    const onSelectCategory = (category?: VideoCategory) => {
        if (category) {
            navigate(`/category/${category.id}`, { state: { category } });
        } else {
            navigate('/');
        }
    }

    const { key, state } = useLocation();
    const { categoryId } = useParams();

    const goBack = () => {
        setVideos([]);
        navigate(-1);
    }

    useEffect(() => {
        if (state) {
            setCategory(state.category);
        } else if (categoryId) {
            const categoryResponseParams = new URLSearchParams({
                ...DEFAULT_REQUEST_ARGS,
            });

            api.get<AppVideoCategoryApiResponse>(`/app-video-category/${categoryId}`, { params: categoryResponseParams }).then((categoryResponse) => {
                setCategory({
                    id: categoryResponse.data.id,
                    name: he.decode(categoryResponse.data.name),
                    parent: categoryResponse.data.parent,
                    order: typeof categoryResponse.data.acf.order_number === 'number' ? categoryResponse.data.acf.order_number : parseInt(categoryResponse.data.acf.order_number),
                });
            });
        } else {
            setCategory(undefined);
        }
    }, [state, categoryId]);

    if (categoryId && !category) {
        return null;
    }

    return (
        <Layout header={<Header />}>
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-1">
                    <h2 className="text-xl small-caps text-center">
                        {category ? category.name : 'Categories'}
                    </h2>
                    {videos.length > 0 && (
                        <span className="text-sm text-slate-500 text-center">{videos.length} videos</span>
                    )}
                    {(category && key !== 'default') && (
                        <div className="flex items-center justify-center gap-4 mt-3">
                            <button className="small-caps text-sm hover:text-cyan-700 transition-colors"
                                onClick={goBack}>
                                [ go back ]
                            </button>
                        </div>
                    )}
                </div>
                <Categories parent={category} onSelectCategory={(onSelectCategory)} />
                <Videos categoryId={category?.id || 0} onVideosLoaded={(videos) => setVideos(videos)} />
            </div>
        </Layout>
    )
};

export default Main;